{
  "name": "railmen-frontend",
  "version": "1.2.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "rts": "node --max_old_space_size=8048 ./node_modules/@angular/cli/bin/ng serve --configuration=test",
    "rtd": "node --max_old_space_size=8048 ./node_modules/@angular/cli/bin/ng build --configuration=test & node ./node_scripts/deploy-railmen-testserver.js  && npm run sentry:sourcemaps",
    "rtdj": "node --max_old_space_size=8048 ./node_modules/@angular/cli/bin/ng build --configuration=test & node ./node_scripts/deploy-railmen-testserver-joshi.js  && npm run sentry:sourcemaps",
    "rps": "node --max_old_space_size=8048 ./node_modules/@angular/cli/bin/ng serve --configuration=production",
    "rpd": "node --max_old_space_size=8048 ./node_modules/@angular/cli/bin/ng build --configuration=production & node ./node_scripts/deploy-railmen-prodserver.js  && npm run sentry:sourcemaps",
    "rpdj": "node --max_old_space_size=8048 ./node_modules/@angular/cli/bin/ng build --configuration=production & node ./node_scripts/deploy-railmen-prodserver-joshi.js  && npm run sentry:sourcemaps",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject ./dist/railmen_frontend/browser && sentry-cli sourcemaps upload ./dist/railmen_frontend/browser"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.0.9",
    "@angular/cdk": "^17.0.5",
    "@angular/common": "^17.0.9",
    "@angular/compiler": "^17.0.9",
    "@angular/core": "^17.0.9",
    "@angular/forms": "^17.0.9",
    "@angular/platform-browser": "^17.0.9",
    "@angular/platform-browser-dynamic": "^17.0.9",
    "@angular/router": "^17.0.9",
    "@fortawesome/angular-fontawesome": "^0.14.1",
    "@fortawesome/fontawesome-svg-core": "^6.5.1",
    "@fortawesome/free-solid-svg-icons": "^6.5.1",
    "@sentry/angular-ivy": "^7.93.0",
    "@sentry/cli": "^2.25.0",
    "@sentry/webpack-plugin": "^2.10.2",
    "inquirer": "8.0.0",
    "lodash": "^4.17.21",
    "ngx-bootstrap": "^12.0.0",
    "ngx-file-drop": "^16.0.0",
    "process": "^0.11.10",
    "rxjs": "~7.8.1",
    "ssh-deploy-release": "^4.0.1",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.10",
    "@angular/cli": "^17.0.10",
    "@angular/compiler-cli": "^17.0.9",
    "@types/jasmine": "~5.1.4",
    "@types/lodash": "^4.17.13",
    "jasmine-core": "~5.1.1",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.2.2"
  }
}
